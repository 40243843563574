<template>
  <div class="mallbox" :class="{ 'mb-mallbox': isMinDevice }">
    <div class="center">
      <div class="commercial">
        <img class="w100" :src="onlineUrl + finalFuncImgs[2]" alt="" />
      </div>
      <div class="mallmain">
        <div v-if="!isMinDevice" class="crumb flex-s">
          <div class="div1">Home</div>
          <img src="@/assets/images/Mall/crumbarrow.png" alt="" />
          <div class="div2">Shop</div>
        </div>
        <div class="listmain" :class="{ 'mb-listmain': isMinDevice }">
          <div v-if="!isMinDevice" class="m_left">
            <div class="l_top">
              <div
                style="
                  height: 0.5rem;
                  line-height: 0.5rem;
                  padding-left: 0.2rem;
                  font-size: 0.2rem;
                  color: #fff;
                  box-sizing: border-box;
                  width: 100%;
                  background: #113975;
                ">
                Classify
              </div>
              <div class="list-box">
                <div class="point">
                  <div
                    @click="changeTypeOnClick(item)"
                    v-for="item in finalHomeGoods"
                    :key="item.id"
                    class="classify"
                    :class="{ acclassify: activeType == item.id }">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="l_botton">
              <div class="list point GSAPwidth">
                <div
                  @click="
                    $router.push({
                      path: '/MallDetail',
                      query: { id: item.id },
                    })
                  "
                  v-for="item in showHotGoodsList"
                  :key="item.id"
                  class="itembox">
                  <img :src="item.image" alt="" />
                  <div class="item_r">
                    <p>
                      {{ item.title }}
                    </p>
                    <span>${{ item.price }}</span>
                    <div class="addbtn" @click.stop="addToCarOnClick(item)">
                      ADD TO CART
                    </div>
                  </div>
                  <div v-if="item.flag == 'hot'" class="float-img-new"></div>
                </div>
              </div>
              <div class="page-box">
                <div
                  v-show="hotGoodsList.length > 2"
                  @click="changePage"
                  class="left-icon active-icon">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <div
                  v-show="hotGoodsList.length > 2"
                  @click="changePage"
                  class="right-icon active-icon">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div> -->
          </div>
          <div v-if="isMinDevice" class="mb-m_left">
            <div class="left-title">Classify</div>
            <div class="left-list-box">
              <div
                @click="changeTypeOnClick(item)"
                v-for="item in finalHomeGoods"
                :key="item.id"
                class="classify-item flex-c"
                :class="{ acclassify: activeType == item.id }">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="m_right" :class="{ 'mb-m_right': isMinDevice }">
            <!-- 排序 -->
            <div class="sort flex-s">
              <div class="left-title">Sortord</div>
              <Cselect
                v-if="!isMinDevice"
                :width="3.2"
                :selectList="selectList"
                v-model="selectItem" />
              <Cselect
                v-if="isMinDevice"
                :width="8.1413"
                :height="1.1265"
                :selectList="selectList"
                v-model="selectItem" />
            </div>
            <!-- 商品列表 -->
            <div v-if="!isMinDevice" class="goodsboxlist-list">
              <div
                @click="
                  $router.push({ path: '/MallDetail', query: { id: item.id } })
                "
                v-for="item in isMinDevice ? mbGoodsList : goodsList"
                :key="item.id"
                class="goodsbox point">
                <div class="imgbox">
                  <img class="w100 h100" :src="item.image" alt="" />
                </div>
                <div
                  class="flex-c"
                  style="
                    font-size: 0.2rem;
                    font-weight: bold;
                    margin-bottom: 0.04rem;
                  ">
                  stock：{{ item?.stocks }}
                </div>
                <div class="info">
                  {{ item.title }}
                </div>
                <div class="price flex-b">
                  <div class="bean">${{ item.marketprice }}</div>
                  <!-- <div class="vipbean">${{ item.price }}</div> -->
                  <div class="bottom-vip-price vipbean">
                    ${{ item.price }}
                    <div class="img">
                      <img
                        class="w100 h100"
                        src="@/assets/images/pc/pc-home-icon12.png"
                        alt=""
                        srcset="" />
                    </div>
                  </div>
                </div>
                <div class="btnbox flex-b point">
                  <!-- <div class="add">ADD TO CART</div> -->
                  <div
                    v-if="item?.stocks > 0"
                    @click.stop="addToCarOnClick(item)"
                    class="check flex-c">
                    ADD TO CART
                  </div>
                  <div
                    v-else
                    class="check flex-c stockstips point"
                    :class="{ 'mb_stocktips': isMinDevice }"
                    @click.stop="stocktipsclick(item)">
                    Restock reminder
                  </div>
                </div>
                <!-- {{item.stocks}} -->
                <!-- <div v-if="!item.stocks" class="float-img-soldout"></div> -->
                <div v-if="item.flag == 'hot'" class="float-img-new"></div>
              </div>
            </div>
            <div
              v-if="isMinDevice"
              class="goodsboxlist-list"
              :class="{ 'mb-goodsboxlist-list': isMinDevice }">
              <van-list
                class="vant-list"
                v-model="loading"
                :finished="finished"
                loading-text="loading"
                finished-text="finished"
                @load="onLoad">
                <div
                  @click="
                    $router.push({
                      path: '/MallDetail',
                      query: { id: item.id },
                    })
                  "
                  v-for="item in isMinDevice ? mbGoodsList : goodsList"
                  :key="item.id"
                  class="goodsbox point">
                  <div class="imgbox">
                    <img class="w100 h100" :src="item.image" alt="" />
                  </div>
                  <div
                    class="flex-c"
                    style="
                      font-size: 0.7rem;
                      font-weight: bold;
                      margin-bottom: 0.2rem;
                    ">
                    stock：{{ item?.stocks }}
                  </div>
                  <div class="info elips-3">
                    {{ item.title }}
                  </div>
                  <div class="price flex-b">
                    <div class="bean">${{ item.marketprice }}</div>
                    <!-- <div class="vipbean">${{ item.price }}</div> -->
                    <div class="bottom-vip-price vipbean">
                      ${{ item.price }}
                      <div class="img">
                        <img
                          class="w100 h100"
                          src="@/assets/images/pc/pc-home-icon12.png"
                          alt=""
                          srcset="" />
                      </div>
                    </div>
                  </div>
                  <div class="btnbox flex-b point">
                    <!-- <div class="add">ADD TO CART</div> -->
                    <div
                      v-if="item?.stocks > 0"
                      @click.stop="addToCarOnClick(item)"
                      class="check flex-c">
                      ADD TO CART
                    </div>
                    <div
                      v-else
                      class="check flex-c stockstips point"
                      :class="{ 'mb_stocktips': isMinDevice }"
                      @click.stop="stocktipsclick(item)">
                      Restock reminder
                    </div>
                  </div>

                  <!-- <div v-if="!item.stocks" class="float-img-soldout"></div> -->
                  <div v-if="item.flag == 'hot'" class="float-img-new"></div>
                </div>
              </van-list>
            </div>
            <!-- 分页 -->
            <div
              v-if="!isMinDevice"
              style="width: 100%; display: flex; flex-direction: row-reverse">
              <div class="paging point flex-c">
                <div @click="pageDownOnClick" class="lefttag"></div>
                <div>{{ page }}</div>
                <div @click="pageUpOnClick" class="righttag"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="float-car-confirm"
      :class="{ 'mb-float-car-confirm': isMinDevice }"
      v-show="floatCarShow">
      <div class="close-btn" @click="floatCarShow = false"></div>
      <div class="top flex-s">
        <div class="top-left">
          <img class="w100 h100" :src="floatCarItem.image" alt="" srcset="" />
        </div>
        <div class="top-right">
          <div class="name">
            {{ floatCarItem.title }}
          </div>
          <div class="tip">Added to your shopping cart</div>
        </div>
      </div>
      <div class="bottom-btn flex-b">
        <div class="btn1 flex-c" @click="goToCarOnClick">VIEW CART</div>
        <div @click="goToCarOnClick" class="btn1 flex-c">CHECK OUT</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mallGoodsListApi,
  addCarApi,
  getHotListApi,
  stock_noticeApi,
} from "@/network/api.js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      selectItem: "",
      goodsList: [],
      mbGoodsList: [],
      hotGoodsList: [],
      hotType: true,
      floatCarShow: false,
      floatCarItem: {},
      activeType: "",
      Timer: "",
      type: "marketprice",
      sort: "asc",
      page: 1,
      TotalPages: 1,
      loading: false,
      finished: false,
      TotalItems: 99999,
    };
  },
  computed: {
    ...mapGetters(["isVip", "isLogin", "finalFuncImgs"]),
    ...mapState(["homeGoods", "onlineUrl", "isMinDevice", "carList"]),
    showHotGoodsList() {
      if (this.hotGoodsList.length > 2) {
        if (this.hotType) {
          return this.hotGoodsList.slice(0, 2);
        } else {
          return this.hotGoodsList.slice(2, 4);
        }
      } else {
        return this.hotGoodsList;
      }
    },
    selectList() {
      return [
        {
          label: "Price from low to high",
          value: 1,
        },
        {
          label: "Price from high to low",
          value: 2,
        },
        {
          label: "Listing time from low to high",
          value: 3,
        },
        {
          label: "Listing time from high to low",
          value: 4,
        },
      ];
    },
    finalHomeGoods() {
      const params = {
        id: 888,
        name: "hot",
      };
      const array = JSON.parse(JSON.stringify(this.homeGoods));
      array.unshift(params);
      return array;
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route: {
      handler() {
        this.activeType = this.$route.query?.shopType || 888;
        this.getGoodsList();
      },
      deep: true,
      immediate: true,
    },
    selectItem: {
      handler(newVal) {
        switch (newVal) {
          case 1:
            this.type = "marketprice";
            this.sort = "asc";
            break;

          case 2:
            this.type = "marketprice";
            this.sort = "desc";
            break;

          case 3:
            this.type = "createtime";
            this.sort = "asc";
            break;

          case 4:
            this.type = "createtime";
            this.sort = "desc";
            break;

          default:
            break;
        }
        this.page = 1;
        this.getGoodsList();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["getCartList"]),
    ...mapMutations(["setShowTipPopup"]),
    async init() {
      await this.getHotList();
      await this.getGoodsList();
      console.log(12345, this.homeGoods);
    },
    async stocktipsclick(item) {
      let params = {
        goods_id: item.id,
      };
      const res = await stock_noticeApi(params);
      if (res.code == 1) {
        this.$message.success("Replenishment reminder successful");
      }
    },
    goToCarOnClick() {
      if (!this.carList?.length) {
        this.$message.error("The shopping cart is empty");
        return false;
      }
      this.$router.push("/car");
    },
    async onLoad() {
      // this.loading = true;
      if (this.mbGoodsList.length !== 0) {
        this.page += 1;
      }
      if (this.mbGoodsList.length < this.TotalItems) {
        await this.getGoodsList();
        if (
          !this.mbGoodsList
            .map((item) => item.id)
            .includes(this.goodsList[0]?.id)
        ) {
          this.mbGoodsList.push(...this.goodsList);
        }
        this.loading = false;
      } else {
        this.finished = true;
      }

      // await this.pageUpOnClick();
      // this.loading = false;
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求

      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }

      //   // 加载状态结束
      //   this.loading = false;

      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
    },
    pageDownOnClick() {
      if (this.page > 1) {
        this.page--;
        this.getGoodsList();
      }
    },
    pageUpOnClick() {
      if (this.page < this.TotalPages) {
        this.page++;
        this.getGoodsList();
      }
    },
    dhFun() {
      gsap.fromTo(
        ".GSAPwidth",
        {
          x: 300,
        },
        {
          x: 0,
          duration: 0.3,
        }
      );
    },
    changePage() {
      this.hotType = !this.hotType;
      this.dhFun();
    },
    changeTypeOnClick(item) {
      this.activeType = item.id;
      this.page = 1;
      this.$router.push({
        path: "/mall",
        query: {
          shopType: item.id,
        },
      });
    },
    async getHotList() {
      const params = {
        flag: "hot",
      };
      const res = await getHotListApi(params);
      this.hotGoodsList = res.data.Items;
    },
    async addToCarOnClick(item) {
      if (!this.isLogin) {
        this.$message.error("Please login first");
        return false;
      }
      if (!this.isVip) {
        this.$message.error(
          "Please contact customer service to obtain VIP for free"
        );
        return false;
      }
      clearTimeout(this.Timer);
      const params = {
        goods_id: item.id,
        nums: 1,
      };

      try {
        const res = await addCarApi(params);
        this.getCartList();
        this.floatCarShow = true;
        this.Timer = setTimeout(() => {
          this.floatCarShow = false;
        }, 3000);
        this.floatCarItem = item;
      } catch (error) {
        this.setShowTipPopup(true);
      }
    },
    async getGoodsList() {
      const params = {
        type: this.type,
        sort: this.sort,
        page: this.page,
      };
      if (this.activeType) {
        params.category_id = this.activeType;
      }
      const res = await mallGoodsListApi(params);
      this.TotalPages = res.data.TotalPages;
      this.TotalItems = res.data.TotalItems;
      this.goodsList = res.data.Items;
      if (this.page == 1) {
        this.mbGoodsList = [];
        this.mbGoodsList.push(...this.goodsList);
      }
      if (this.activeType == 888) {
        this.goodsList = this.hotGoodsList;
        this.mbGoodsList = this.hotGoodsList;
      }
      this.loading = false;
      console.log(12355555, this.activeType, this.goodsList, this.mbGoodsList);
    },
  },
};
</script>

<style lang="scss">
.mallbox {
  width: 100%;
  height: 100%;
  position: relative;

  .center {
    width: 14.74rem;
    margin: 0 auto;
    height: 13.88rem;
    overflow: hidden;

    .commercial {
      width: 100%;
      // height: 0.8rem;
      // background-color: #dfdfdf;
      margin-top: 0.66rem;
    }
    .mallmain {
      width: 100%;
      margin-top: 0.8rem;

      .crumb {
        width: 3.43rem;
        height: 0.42rem;
        line-height: 0.42rem;
        box-shadow: #eee 0rem 0rem 0.05rem 0.05rem;
        margin-bottom: 0.16rem;
        padding-left: 0.3rem;
        margin-left: 0.1rem;
        font-size: 0.18rem;
        box-sizing: border-box;
        color: #848484;
        .div1 {
        }
        .div2 {
          font-weight: 600;
          color: #000;
        }
        img {
          display: block;
          width: 0.1rem;
          height: 0.12rem;
          margin: 0 0.2rem;
        }
      }

      .listmain {
        width: 100%;
        // height: 8.47rem;
        display: flex;
        justify-content: space-between;

        .m_left {
          width: 3.44rem;
          height: 100%;
          background: rgba(255, 255, 255, 0.1);
          box-shadow: 0 0.04rem 0.1rem 0.04rem rgba(0, 0, 0, 0.1);
          border-radius: 0.06rem;
          opacity: 1;
          border: 0.01rem solid rgba(0, 93, 179, 0.1);
          margin: 0 0.1rem;

          .list {
            padding-top: 0.47rem;
          }

          .l_top {
            width: 2.88rem;
            // height: 3.28rem;
            background-color: #d4d4d4;
            // background-image: url("../../assets/images/Mall/l_top.png");
            // background-repeat: no-repeat;
            background-size: 100% auto;
            margin: 0.24rem auto;

            .list-box {
              // overflow-y: auto;
              width: 100%;
              // height: 100%;
            }
            .classify {
              width: 100%;
              height: 0.46rem;
              padding-left: 0.2rem;
              line-height: 0.46rem;
              box-sizing: border-box;
            }

            .acclassify {
              background: url("../../assets/images/Mall/acclassify.png");
            }
          }

          .l_botton {
            width: 2.88rem;
            height: 3.6rem;
            background: url("../../assets/images/Mall/l_botton.png") no-repeat;
            background-size: 100% 120%;
            margin: 0 auto 0.24rem;
            padding: 0.2rem 0.1rem 0rem 0.1rem;
            box-sizing: border-box;
            overflow: hidden;
            .itembox {
              // width: 2.67rem;
              height: 1.08rem;
              display: flex;
              justify-content: space-between;
              // margin: 0.03rem auto 0;
              margin-bottom: 0.06rem;
              position: relative;

              img {
                // width: 1rem;
                height: 1.08rem;
              }

              .item_r {
                width: 1.52rem;
                padding: 0.05rem 0;
                display: flex;
                align-content: space-between;
                flex-wrap: wrap;

                p {
                  margin: 0;
                  width: 1.52rem;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                span {
                  // display: block;
                  // width: 1.52rem;
                  // height: 0.41rem;
                  margin: 0.1rem 0;
                  // line-height: 0.04rem;
                }

                .addbtn {
                  width: fit-content;
                  padding: 0 0.11rem;
                  height: 0.27rem;
                  font-size: 0.12rem;
                  font-weight: 900;
                  color: #000000;
                  line-height: 0.27rem;
                  text-align: center;
                  background: #ffff;
                  box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.1);
                  border: radius 0.02rem;
                  opacity: 1;
                  border: 0.01rem solid rgba(0, 0, 0, 0.1);
                }

                .addbtn:hover {
                  background: #5ba7ff;
                  color: #ffff;
                }
              }
              .float-img-new {
                width: 0.27rem;
                height: 0.14rem;
                position: absolute;
                right: 0;
                left: 0;
                @include pcurl("pc-home-icon23.png");
              }
              // .float-img-soldout {
              //   width: 0.44rem;
              //   height: 0.3745rem;
              //   position: absolute;
              //   right: 0;
              //   left: 0;
              //   @include pcurl("pc-home-icon22.png");
              // }
            }
            .page-box {
              margin-top: 0.2rem;
              margin-bottom: 0.2rem;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font-size: 0.26rem;
              color: #999;
              .left-icon {
                margin: 0 0.1rem;
              }
              .active-icon {
                color: #000;
                cursor: pointer;
              }
            }
          }
        }
        .mb-m_left {
          display: flex;
          align-items: flex-start;
          margin-bottom: 0.6144rem;
          .left-title {
            font-size: 0.6144rem;
            color: #003879;
            margin-right: 0.768rem;
            width: 2.5602rem;
            flex-shrink: 0;
          }
          .left-list-box {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .classify-item {
              font-size: 0.512rem;
              color: #000;
              padding: 0.2048rem 0.4096rem;
              border: 0.0512rem solid rgba(156, 156, 156, 1);
              margin-right: 0.3072rem;
              margin-bottom: 0.4096rem;
            }
            .acclassify {
              // background: url("../../assets/images/Mall/acclassify.png");
              background: rgba(0, 56, 121, 1);
              color: #fff;
            }
          }
        }

        .m_right {
          width: 10.94rem;
          height: 100%;

          .sort {
            height: 0.4rem;
            position: relative;
            color: #b8b8b8;
            margin-bottom: 0.25rem;
            .left-title {
              margin-right: 0.16rem;
            }
          }

          .goodsboxlist-list {
            margin-top: 0.25rem;
            width: 100%;
            // height: 7.58rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .goodsbox {
              width: 3.06rem;
              // height: 3.65rem;
              background: url("../../assets/images/Mall/goodsbg.png") no-repeat;
              background-size: 100% 100%;
              border-radius: 0.04rem;
              // overflow: hidden;
              position: relative;
              margin-bottom: 0.24rem;
              padding-bottom: 0.34rem;

              .imgbox {
                width: 1.8rem;
                // height: 1.97rem;
                margin: 0.1rem auto 0rem;
              }

              .info {
                width: 2.86rem;
                min-height: 0.44rem;
                max-height: 0.46rem;
                text-align: left;
                margin: 0 auto;
                font-size: 0.18rem;
                font-weight: 500;
                color: #000000;
                line-height: 0.21rem;
                padding-left: 0.2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                box-sizing: border-box;
              }

              .price {
                width: 100%;
                height: 0.4rem;
                padding: 0 0.45rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;
                font-weight: 500;
                box-sizing: border-box;
                color: #000000;
                font-size: 0.2rem;
                // .bean {
                //   width: 50%;
                // }

                // .vipbean {
                //   width: 50%;
                // }
                // .bottom-vip-price {
                //   font-weight: bold;
                //   position: relative;

                //   .img {
                //     position: absolute;
                //     top: -0.23rem;
                //     right: -0.24rem;
                //     width: 0.47rem;
                //     height: 0.47rem;
                //     transform: rotate(13deg);
                //     z-index: -1;
                //   }
                // }
              }

              .btnbox {
                // height: 0.36rem;
                text-align: center;
                // line-height: 0.36rem;
                font-weight: 900;

                // .add {
                //   width: 50%;
                //   height: 0.36rem;
                //   background: #d3d3d3;
                //   border-radius: 0.02rem 0 0 0.02rem;
                // }

                .check {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 0.36rem;
                  background: #0076ff;
                  border-radius: 0 0.02rem 0.02rem 0;
                  opacity: 1;
                  color: #ffff;
                }
                .stockstips {
                  color: #fff;
                  background: #f6861e;
                }
                .mb_stocktips {
                  font-size: 0.6rem;
                }
              }

              .float-img-new {
                width: 0.47rem;
                height: 0.24rem;
                position: absolute;
                right: 0;
                top: 0;
                @include pcurl("pc-home-icon23.png");
              }
              // .float-img-soldout {
              //   width: 0.64rem;
              //   height: 0.5745rem;
              //   position: absolute;
              //   right: 0;
              //   top: 0;
              //   @include pcurl("pc-home-icon22.png");
              // }
            }
          }
          .goodsboxlist-list::after {
            width: 3.06rem;
            content: "";
            display: block;
          }
          .mb-goodsboxlist-list {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            .vant-list {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
              .goodsbox {
                width: 7.9877rem;
                height: unset;
                border-radius: 0.14rem;
                overflow: hidden;
                padding-bottom: 1.48rem;
                margin-bottom: 0.6144rem;
                .imgbox {
                  width: 100%;
                }
                .info {
                  width: 100%;
                  font-size: 0.6144rem;
                  max-height: unset;
                  min-height: 2.27rem;
                  line-height: unset;
                  // overflow: unset;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  margin-bottom: 0.3rem;
                  padding: 0 0.5217rem;
                  box-sizing: border-box;
                }
                .price {
                  font-size: 0.6144rem;
                  margin-bottom: 0.3rem;
                  padding: 0 0.5217rem;
                  box-sizing: border-box;
                  .bottom-vip-price {
                    font-size: 0.6144rem;
                    .img {
                      width: 1.1777rem;
                      height: 1.1777rem;
                      top: -0.5rem;
                      right: -0.5rem;
                    }
                  }
                }
                .btnbox {
                  .check {
                    height: 1.2801rem;
                    font-size: 0.6144rem;
                  }
                }
                .float-img-new {
                  width: 1.4849rem;
                  height: 0.8705rem;
                }
              }
            }
            .van-list__loading {
              width: 100%;
            }
            .van-list__finished-text {
              width: 100%;
            }
          }

          .paging {
            width: 2.62rem;
            height: 0.35rem;
            margin-top: 0.4rem;
            display: flex;
            justify-content: space-between;
            font-size: 0.26rem;

            .lefttag {
              width: 0.82rem;
              height: 0.35rem;
              background: url("../../assets/images/Mall/lefttag.png") no-repeat;
              background-size: 100% 100%;
            }

            .righttag {
              width: 0.82rem;
              height: 0.35rem;
              background: url("../../assets/images/Mall/righttag.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        .mb-m_right {
          width: unset;
          .sort {
            margin-bottom: 1.6385rem;
            .left-title {
              font-size: 0.6144rem;
              color: #003879;
              margin-right: 0.768rem;
              width: 2.5602rem;
              flex-shrink: 0;
            }
          }
        }
      }
      .mb-listmain {
        display: unset;
      }
    }
  }
}
.mb-mallbox {
  padding: 0 1.0241rem;
  box-sizing: border-box;
  .center {
    width: 100%;
    height: unset;
    overflow: unset;
  }
}
</style>
